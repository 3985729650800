var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"item-key":"id","loading":_vm.isLoading,"headers":_vm.displayedHeader,"items":_vm.additionalCostListItem,"server-items-length":_vm.totalItems,"options":_vm.pagination,"footer-props":{showCurrentPage: true, showFirstLastPage: true, 'items-per-page-options': _vm.$_item_per_page}},on:{"update:options":function($event){_vm.pagination=$event},"click:row":_vm.rowClicked},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.createdBy)+" "+_vm._s(_vm.dateFormat(item.createdAt))+" ")])]}},{key:"item.reason",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.reason))])]}},{key:"item.needConfirm",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.needConfirm ? ' Kena Pajak' : ' Tidak Kena Pajak'))])]}},{key:"item.isActive",fn:function(ref){
var item = ref.item;
return [_c('v-select',{staticClass:"mt-5 pa-0 caption",staticStyle:{"width":"8rem"},attrs:{"outlined":"","dense":"","item-value":"value","item-text":"key","items":_vm.itemStatus,"disabled":!_vm.userAccess.canUpdate,"loading":item.isLoading},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();},"change":function($event){return _vm.onChangeIsActive(item, $event)}},model:{value:(item.isActive),callback:function ($$v) {_vm.$set(item, "isActive", $$v)},expression:"item.isActive"}})]}},{key:"footer.page-text",fn:function(props){return [_c('span',[_vm._v(" "+_vm._s(_vm.$_strings.additionalCost.ADDITIONAL_COST_DATABASE)+" "),(_vm.additionalCostListItem.length)?_c('span',[_vm._v(" "+_vm._s(props.pageStart)+"-"+_vm._s(props.pageStop)+" of "+_vm._s(props.itemsLength)+" ")]):_vm._e()])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }