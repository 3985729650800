<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="700"
  >
    <v-card :loading="isLoading">
      <div class="d-flex justify-end">
        <v-toolbar-items>
          <v-btn
            icon
            color="black"
            dark
            class="mt-2 mr-3"
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </div>
      <v-form
        ref="form"
        :disabled="!userAccess.canUpdate"
        v-model="valid"
        lazy-validation
      >
        <v-card-text>
          <v-row>
            <v-col cols="12" class="pb-0">
              <v-text-field
                :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.additionalCost.CREATE)]"
                :label="$_strings.additionalCost.CREATE"
                outlined
                v-model="form.reason"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="pb-0">
              <v-text-field
                :label="$_strings.additionalCost.CREATE_DESC"
                outlined
                v-model="form.description"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-select
                v-model="form.costType"
                outlined
                dense
                :items="listCostType"
              ></v-select>
            </v-col>
            <v-col cols="12" class="py-0">
              <common-auto-complete-items
                ref="refListOracleCost"
                type="listOracleCost"
                searchName="name"
                item-value="id"
                item-text="name"
                outlined
                clearable
                v-model="form.oracleCostInternalId"
                :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.additionalCost.ORACLE_INTERNAL)]"
                :label="$_strings.additionalCost.ORACLE_INTERNAL"
                :filter="filterListOracleCost"
                :items="listOracleCost"
                @updateItems="updateListOracle"
                @change="form.oracleCostId = event.target.value"
              />
            </v-col>
            <v-col cols="12" class="py-0">
              <common-auto-complete-items
                ref="refListOracleCost"
                type="listOracleCost"
                searchName="name"
                item-value="id"
                item-text="name"
                outlined
                clearable
                v-model="form.oracleCostExternalId"
                :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.additionalCost.ORACLE_EXTERNAL)]"
                :label="$_strings.additionalCost.ORACLE_EXTERNAL"
                :filter="filterListOracleCost"
                :items="listOracleCost"
                @updateItems="updateListOracle"
                @change="form.oracleCostId = event.target.value"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn
            color="white"
            @click="dialog = false"
          >
            {{$_strings.common.CANCEL}}
          </v-btn>
          <v-btn
            v-if="userAccess.canUpdate"
            color="primary"
            @click="submit"
            :disabled="isLoading"
          >
            {{form.id ? $_strings.common.EDIT: $_strings.common.SAVE}}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { filterDuplicateValues } from '@/helper/commonHelpers';

export default {
  watch: {
    dialog(newVal) {
      if (!newVal) {
        this.$refs.form.reset();
        this.listOracleCost = [];
        this.filterListOracleCost = {
          page: 0,
          size: 50,
          search: '',
        };
        this.form = {
          reason: '',
          type: 'COST',
          needConfirm: false,
          isFreeText: null,
          isActive: true,
          isFli: false,
          isFixedPrice: false,
          isOnCall: false,
          oracleCostInternalId: 0,
          oracleCostExternalId: 0,
          description: '',
        };
        return;
      }
      if (this.form.id) {
        this.filterListOracleCost.search = this.form.oracleCostName;
        this.$nextTick(() => {
          this.$refs.refListOracleCost.fetchData();
        });
      }
    },
  },
  data() {
    return {
      isLoading: false,
      dialog: false,
      valid: false,
      itemOracle: [],
      listOracleCost: [],
      listCostType: ['FCL', 'LCL', 'FIXED_PRICE'],
      filterListOracleCost: {
        page: 0,
        size: 25,
        search: '',
      },
      form: {
        reason: '',
        type: '',
        needConfirm: false,
        isFreeText: null,
        isActive: true,
        isFli: false,
        isFixedPrice: false,
        isOnCall: false,
        oracleCostInternalId: 0,
        oracleCostExternalId: 0,
      },
    };
  },
  methods: {
    filterDuplicateValues,
    updateListOracle(items) {
      const _items = items.map((item) => ({
        id: item.id,
        code: item.code,
        name: `${item.name} - ${item.description}`,
      }));
      this.listOracleCost = this.filterDuplicateValues([...this.listOracleCost, ..._items]);
    },
    async submit() {
      if (!this.$refs.form.validate()) return;
      try {
        this.isLoading = true;
        await this.$_services.masterData.additionalAddEdit(this.form, this.form.id);
        if (this.form.id) {
          this.$dialog.notify.success('Berhasil diubah');
        } else {
          this.$dialog.notify.success('Berhasil menambahkan');
        }
        this.dialog = false;
        this.$emit('fetchAdditionalCostList');
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
