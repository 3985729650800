<template>
  <v-container fluid>
    <!-- <v-row
      dense
      align="center"
      class="mb-4"
    >
      <v-col cols="auto">
        <v-btn
          v-if="userAccess.canCreate"
          elevation="1"
          color="primary"
          dark
          small
          @click="$refs.dialogAcouteCost.dialog = true"
        >
          {{$_strings.additionalCost.ADD}}
        </v-btn>
      </v-col>
      <v-col cols="auto">
        <MasterDataCsvUpload
          dataTypeProp="cost"
          :importTypeProp="$_strings.importType.COST_OPTION"
          :uploadCsvProp="uploadCsv"
          :downloadTemplateCsvProp="downloadTemplateCsv"
          @fetchList="fetchList"
        />
      </v-col>
      <v-col
        cols="auto"
        md="4"
        class="ml-md-auto"
      >
        <v-row
          dense
          justify="end"
        >
          <v-col cols="auto">
            <FilterList
              @setNewFilters="setNewFilters"
            />
          </v-col>
          <v-col cols="auto" md="12">
            <v-text-field
              dense
              hide-details
              outlined
              class="caption"
              v-model="search"
              @keyup.enter="setNewFilters({ name: search })"
              :placeholder="$_strings.additionalCost.SEARCH_TEXT"
            >
              <template v-slot:prepend-inner>
                <v-icon @click="setNewFilters({ name: search })">
                  mdi-magnify
                </v-icon>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row> -->
    <List
      :key="key_list"
      :filters="filters"
      @showDialogAdditionalCost="showDialogAdditionalCost"
    />
    <AcouteCost
      ref="dialogAcouteCost"
      @fetchAdditionalCostList="fetchList"
    />
  </v-container>
</template>

<script>
import List from './List.vue';
// import FilterList from './FilterList.vue';
import AcouteCost from './Dialog/AddAcouteCost.vue';

// const MasterDataCsvUpload = () => import('../Common/MasterDataCsvUpload');

export default {
  name: 'acoute-cost',
  components: {
    List,
    // FilterList,
    AcouteCost,
    // MasterDataCsvUpload,
  },
  data() {
    return {
      key_list: 0,
      search: this.$route.query.name || '',
      filters: {
        needConfirm: this.$route.query.needConfirm || '',
        isActive: this.$route.query.isActive || '',
        name: this.$route.query.name || '',
        oracleCostName: this.$route.query.oracleCostName || '',
      },
    };
  },
  methods: {
    fetchList() {
      this.key_list += 1;
    },
    setNewFilters(newFilters) {
      const newQuery = {
        ...this.$route.query,
        ...newFilters,
        page: 1,
      };
      if (JSON.stringify(newQuery).replace(/["]/g, '') !== JSON.stringify(this.$route.query).replace(/["]/g, '')) {
        this.$router.replace({
          query: newQuery,
        });
        this.filters = {
          ...this.filters,
          ...newFilters,
        };
      }
      this.fetchList();
    },
    showDialogAdditionalCost(item) {
      this.$refs.dialogAcouteCost.dialog = true;
      this.$refs.dialogAcouteCost.form = { ...item };
    },
    uploadCsv(formData) {
      return this.$_services.uploads.uploadCsvReason(formData);
    },
    downloadTemplateCsv() {
      return this.$_services.uploads.fetchCsvTemplateDataReason('cost');
    },
  },
};
</script>
